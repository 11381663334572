@import 'reset-css/reset.css';
@import './_toolbox';

@font-face {
  font-family: Walsheim;
  src: url("../../static/font/gt-walsheim-medium.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}


:root {
  font-family: Walsheim, sans-serif;
  
  --c-brand: #e70060;
  --c-text: #000;
  --c-interactive: var(--c-text);
  --c-discrete: #f4f4f4;

  --c-bg: #fff;
  --c-bg--contrast: var(--c-brand);

  --size-grid-gutter: 2.2rem;

  --size-content-stack: 6rem;
  --size-content-stack--large: 20rem;
  --size-design-gutter: 6.4rem;
  --size-bezel: 4rem;

  font-size: screen-ratio-mix(.5, // 40% of the the font-size is relative to the screen width
    1440, // artboard width
    10, // target PX size (base font-size in the design)
  );

  --size-font-size: 1.8rem;
  --size-font-size--hero: 9rem;
  --size-font-size--small: 3.6rem;
  --size-font-size--medium: 5.4rem;
}

::-moz-selection {
  background-color: var(--c-brand);
  color: var(--c-bg);
}

::selection {
  background-color: var(--c-brand);
  color: var(--c-bg);
}

html {
  line-height: 1.5;
  color: var(--c-text);
  background: var(--c-bg);
}

body {
  font-size: var(--size-font-size);
}

img {
  max-width: 100%;
}

a {
  color: var(--c-interactive);
  text-decoration: none;
}

h1, h2, h3, strong, b {
  font-weight: normal;
  line-height: 1.1;
}

.content,
.grid--content .grid__cell {
  > *:first-child {
    margin-top: 0;
  }
}

.content__inner {
  min-height: 100vh;
}

.content__block {
  margin-top: var(--size-content-stack);

  @include viewport(phone) {
    margin-top: calc(var(--size-content-stack) * 2);
  }
  
  &--stack {
    &-none {
      margin-top: var(--size-grid-gutter);
      & > *:first-child {
        margin-top: 0;
      }
    }
    &-large {
      margin-top: var(--size-content-stack--large);

      @include viewport(phone) {
        margin-top: calc(var(--size-content-stack) * 2) ;
      } 
    }
  }

  &--narrow {
    margin-left: calc(12% - var(--size-design-gutter));
    margin-right: calc(12% - var(--size-design-gutter));
  }

  img {
    width: 100%;
    height: auto;
  }
}

.block--inverted {
  color: var(--c-bg);
  background-color: var(--c-text);
  padding: var(--size-grid-gutter);
  li {
    margin-top: calc(var(--size-grid-gutter) * 0.25);
  }
}

.block__title {
  margin-bottom: var(--size-grid-gutter);
}

.hipster-mode .content__block {
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: -1px;
    left: 0;
    right: 0;
    height: var(--size-content-stack);
    transform: translateY(-100%);
    border: solid pink;
    border-width: 2px 0;
    background: linear-gradient(90deg, transparent 50%, pink calc(50% + 1px), transparent calc(50% + 3px));
  }

  &--stack {
    &-none {
      &::before {
        display: none
      }
    }
    &-large {
      &::before {
        content: '';
        height: var(--size-content-stack--large);

        border: solid lime;
        border-width: 2px 0;
        background: linear-gradient(90deg, transparent 50%, lime calc(50% + 1px), transparent calc(50% + 3px));
      }
    }
  }
}

.will-appear {
  //position: relative;
  //filter: grayscale(100%);
  //filter: grayscale(100%) contrast(200%);
  opacity: 0;
  will-change: opacity;
  transition: opacity 500ms linear 200ms,
              transform 1000ms cubic-bezier(.23, 1, .32, 1) 100ms;
  //transform: scale(.65);
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: inset 0 0 0 6rem var(--c-bg);
    will-change: box-shadow;
    transition: box-shadow 1000ms cubic-bezier(.23, 1, .32, 1) 300ms;
  }
  
  &.is-inviewport {
    opacity: 1;
    transform: translateY(0%) scale(1);

    &::after {
      box-shadow: inset 0 0 0 0rem var(--c-bg);
    }
  }
}


.t-title--case,
.t-hero,
.t-large,
.t-medium,
.richtext h1,  
.richtext h2,
.richtext h3,
.richtext h4 {
  margin-top: calc(var(--size-content-stack) - 0.25em);

  &:last-child {
    margin-bottom: 0;
  }

  &.grid__cell {
    // no need for a margin, if its part of the grid
    margin-top: 0;
  }

  &.will-appear {
    //position: relative;
    //filter: grayscale(100%);
    //filter: grayscale(100%) contrast(200%);
    transition: none;

    //transform: scale(.65);
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: -2rem;
      transform: scaleY(1);
      will-change: transform;
      background: var(--c-bg);
      transform-origin: 0 100%;
      transition: transform 1000ms cubic-bezier(.23, 1, .32, 1) 300ms;
    }

    &.is-inviewport {
      &::after {
        transform: scaleY(0);
      }
    }
  }

  .hipster-mode & {
    position: relative;
    &.grid__cell {
      &::before {
        display: none;
      }
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: var(--size-content-stack);
      transform: translateY(-100%);
      margin-top: 0.25em;
      border: solid pink;
      border-width: 2px 0;
      background: linear-gradient(90deg, transparent 50%, pink calc(50% + 1px), transparent calc(50% + 3px));
    }
  }
}

.content__block--intro {
  margin-top: var(--size-content-stack--large);
  margin-bottom: var(--size-content-stack--large);
}


.t-title--case {
  position: relative;
  // makes sure the width is the same as on the overview
  padding-right: calc(var(--size-bezel) * 4); 
}

.t-title {
  span {
    display: block;
  }
}
.richtext h1,
.t-hero,
.t-large {
  font-size: var(--size-font-size--hero);

  @include viewport(phone) {
    font-size: calc(var(--size-font-size--hero) * .8);
  }
  
  &--small {
    font-size: var(--size-font-size--small);

    @include viewport(phone) {
      font-size: calc(var(--size-font-size--small) * .8);
    }
  }

  &--medium {
    font-size: var(--size-font-size--medium);
  }
}

.richtext p,
.t-small {
  font-size: var(--size-font-size--small);

  @include viewport(phone) {
    font-size: calc(var(--size-font-size--small) * .8);
  }
}

.richtext h2,
.t-medium {
  font-size: var(--size-font-size--medium);

    @include viewport(phone) {
      font-size: calc(var(--size-font-size--medium) * .6);
    }
}
.t-large {
  font-size: var(--size-font-size--hero);

  @include viewport(phone) {
    font-size: calc(var(--size-font-size--hero) * .6);
  }
}

.grid__text {
  margin-left: var(--size-grid-gutter);
  margin-bottom: var(--size-grid-gutter);
}

.richtext {
  margin: var(--size-bezel);

  @include viewport(phone) {
    margin: var(--size-bezel) 0;
  }
  
  &--narrow {
    margin-left: calc(var(--size-bezel) * 2);
    margin-right: calc(var(--size-bezel) * 2);

    @include viewport(phone) {
      margin: var(--size-bezel) 0;
    }
  }
}

.richtext h2 {
  margin-top: var(--size-content-stack);
  
}

.richtext p {
  margin-top: var(--size-bezel);
}

.sectionIntro,
.sectionOutro {
  position: relative;

  &__video {
    object-fit: cover;
    width: 100%;
  }

  &--services {
    background: var(--c-discrete);
    top: calc(var(--size-design-gutter) * -1);
    margin: 0 calc(var(--size-design-gutter) * -1);
    padding: var(--size-design-gutter);
  }

  &__text {
    line-height: 1.3;
  }
}

.sectionOutro {
  background: var(--c-discrete);
  padding: var(--size-design-gutter);
  margin: var(--size-content-stack--large) calc(var(--size-design-gutter) * -1) calc(var(--size-content-stack--large) * -1);
  @include viewport(phone) {
    margin: var(--size-content-stack--large) calc(var(--size-design-gutter) * -1) 0;
  }    
}


.service {
  
  & + & {
    margin-top: var(--size-content-stack--large);
  }

  &__player {
    width: 100%;
  }

  &__content {
    display: flex;
    gap: var(--size-design-gutter);

    @include viewport(phone) {
      //display: block;
      flex-direction: column-reverse;
    }
  }

  &__related {
    display: flex;
    margin-top: var(--size-design-gutter);
  }

  &__thumb {
    --size-bezel: 1.5rem;
    height: auto !important;
    aspect-ratio: .75;
    //flex: 0 0 33.33%;
    margin-bottom: 0 !important;

    .project-thumb__title {
      font-size: 1.6rem;
    }
  }

  &__anim {
    order: 2;
    flex: 0 0 40%;
  }

  &__text {
    order: 1;
  }

  &__tags {
    margin-top: var(--size-content-stack);
    font-size: 1em;
    line-height: 1.4;

    li {
      display: inline-block;
    }

    li + li {
      &::before {
        display: inline-block;
        content: '|';

        margin: 0 calc(var(--size-bezel) * .15);
      }
    }
  }
}

.intro {
  position: relative;
  height: 70vh;
  max-height: 86rem;
  overflow: hidden;

  &,
  &__mask {
    background-size: cover;
    background-position: 50% 50%;
  }

  &__mask {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    
    //filter: grayscale(100%);
  }

  &__sizer {
    width: 100%;
    opacity: 0;
  }

  &__video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    transform: translate(-50%, -50%);
  }
}

.design-width {
  padding: 0 var(--size-design-gutter);
  box-sizing: border-box;
  .hipster-mode & {
    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      width: var(--size-design-gutter);

      border: solid fuchsia;
      border-width: 0 1px;
      background: linear-gradient(0deg, transparent 50%, fuchsia calc(50% + 1px), transparent calc(50% + 2px));
    }
    &::before {
      left: 0;
    }
    &::after {
      right: 0;
    }
  }
}

.culture-grid {
  column-gap: 1.5em;
  column-count: 2;
  grid-template-columns: 49% 49%;
  margin-bottom: 50vh;
}

.culture-item {
  position: relative;
  box-sizing: border-box;
  padding: 0 2.5vw;
  width: 100%;
  margin: -10vh 0;
  
  &__image {
    max-width: 100%;

    @include viewport(phone) {
      max-width: 200%;
      width: 200%;
      transform: translateX(-25%);
    }
  }

  &__block {
    position: absolute;
    top: 0;
    left: 2.5vw;
    right: 2.5vw;
    display: block;
    transform: translateY(calc(var(--ratio, 1) * 50%)) translateZ(calc(var(--ratio, 1) * 100px)) scale(calc(.8 + var(--ratio, 1) / 2 - .3)) rotateZ(calc(var(--ratio, 1) * -20deg + var(--rnd, 0) * 40deg));
    will-change: transform;
    z-index: 1;
  }
}

.floatyletter {
  position: fixed;
  opacity: 0;
  
  z-index: 100;
  margin-top: calc(var(--size-font-size--medium) * -.4);
  font-size: var(--size-font-size--medium);
  transform: translate(-50%, -50%) scale(0);
  line-height: 1;
  will-change: transform, opacity;
  //filter: invert(100%);
  color: var(--c-brand);
  pointer-events: none;
}

.grid--content {
  margin-top: 20rem;
  margin-bottom: 20rem;
}

.t-centered {
  text-align: center;
}

.filled {
  background: var(--project-color, var(--c-text));
  color: var(--project-color--contrast, var(--c-bg));
  padding: calc(var(--size-grid-gutter) * 2.5);

  & > .grid__text {
    margin-left: 0;
    margin-bottom: 0;
  }
}

@import './_grid';

.hidden {
  display: none;
}

.richtext .no-stack,
.no-stack {
  margin-top: 0;
}